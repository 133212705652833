<template>
    <div id="unreal-engine-page">

        <h1 style="text-align:center;">Unreal Engine 5 Notes</h1>
        <hr/>

        <h2>Capsule</h2>
        <p>

        </p>

        <h2>Components</h2>
        <p>
            Components can attach other components onto themselves.
        </p>

        <h2>CreateDefaultSubobject()</h2>
        <p>

        </p>

        <h2>Forward Declaration</h2>
        <p>

        </p>

        <h2>->SetupAttachment()</h2>
        <p>

        </p>

        <h2>Static Mesh</h2>
        <p>
            <b>Definition</b>: "Piece of geometry that consists of a static set of polygons."
        </p>

        <h2>UPROPERTY()</h2>
        <p>
            <ul>
                <li>VisibleAnywhere</li>
                <li>EditAnywhere</li>
                <li>VisibleInstanceOnly</li>
                <li>EditInstanceOnly</li>
                <li>VisibleDefaultsOnly</li>
                <li>EditDefaultsOnly</li>
                <li>, BlueprintReadWrite</li>
                <li>, BlueprintReadOnly</li>
            </ul>
        </p>

    </div>
</template>

<script>
export default {
    name: 'UnrealEngine5',
    data() {
        return {

        }
    },
    created()
    {

    },
    mounted()
    {

    }
}
</script>

<style scoped>

</style>
